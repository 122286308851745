// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShareButton_shareButton__6lVFP {
  border: none;
  outline: none;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: #1d1a2c;
}
.ShareButton_shareButton__6lVFP img {
  width: 40px;
  height: 40px;
}
.ShareButton_shareButton__6lVFP:hover {
  background: #3e3a4f;
}
.ShareButton_shareButton__6lVFP:active {
  background: #4f4a63;
}`, "",{"version":3,"sources":["webpack://./src/components/ShareButton/ShareButton.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EAMA,mBCRa;ADEf;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,mBCTiB;ADQrB;AAGE;EACE,mBCXkB;ADUtB","sourcesContent":["@import \"../../variables\";\n\n.shareButton {\n  border: none;\n  outline: none;\n  height: 35px;    \n  display: flex;\n  align-items: center; \n  justify-content: center;\n  border-radius: 15px;\n  \n  img {\n    width: 40px;\n    height: 40px;\n  }\n  background: $border-color;  \n  &:hover {\n    background: $bright-plain-color; \n  }\n  &:active {\n    background: $pressed-plain-color;\n  }\n}\n\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareButton": `ShareButton_shareButton__6lVFP`
};
export default ___CSS_LOADER_EXPORT___;
