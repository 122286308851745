// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paWindow_paWindow__cHiFy {
  width: 140px;
  padding: 3px;
  border-radius: 10px;
  border: 1px #1d1a2c solid;
  background: #030014;
  position: fixed;
  right: -140px;
  top: 70px;
  z-index: 2;
  transition: right 0.2s ease;
}
.paWindow_paWindow__cHiFy.paWindow_active__6u2GX {
  right: 1.5vw;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileActionsWindow/paWindow.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBCPiB;EDQjB,eAAA;EACA,aAAA;EACA,SAAA;EACA,UAAA;EAIA,2BAAA;AAJF;AACE;EACE,YAAA;AACJ","sourcesContent":["@import \"../../variables\";\n\n.paWindow {\n  width: 140px;\n  padding: 3px;\n  border-radius: 10px;  \n  border: 1px $border-color solid;\n  background: $background-color;\n  position: fixed;\n  right: -140px;\n  top: 70px;\n  z-index: 2;\n  &.active{\n    right: 1.5vw;\n  }\n  transition: right 0.2s ease;\n  // justify-content: space-between;\n  // flex-direction: column;\n  // gap: 6px; \n}\n\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paWindow": `paWindow_paWindow__cHiFy`,
	"active": `paWindow_active__6u2GX`
};
export default ___CSS_LOADER_EXPORT___;
