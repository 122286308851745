// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavButton_navButton__qQmZ- {
  height: 40px;
  border: none;
  border-radius: 10px;
  background: #030014;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  padding-left: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #fef9ec;
  width: 95%;
  margin: 6px auto;
  font-family: inherit;
}
.NavButton_navButton__qQmZ- img {
  -webkit-user-select: none;
          user-select: none;
  height: 20px;
}
.NavButton_navButton__qQmZ-:hover {
  background: #1d1a2c;
}
.NavButton_navButton__qQmZ-.NavButton_active__Mz\\+uS {
  background: #3e3a4f;
}`, "",{"version":3,"sources":["webpack://./src/components/NavButton/NavButton.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,mBCNiB;EDOjB,eAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,QAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,cCTW;EDUX,UAAA;EACA,gBAAA;EACA,oBAAA;AADF;AAGE;EACE,yBAAA;UAAA,iBAAA;EACA,YAAA;AADJ;AAGE;EACE,mBClBW;ADiBf;AAIE;EACE,mBCrBiB;ADmBrB","sourcesContent":["@import \"../../variables\";\n\n.navButton {\n  height: 40px;\n  border: none;\n  border-radius: 10px;\n  background: $background-color;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 6px;\n  padding-left: 10px;\n  font-size: 15px;\n  font-weight: 600;\n  color: $text-color;\n  width: 95%;\n  margin: 6px auto;\n  font-family: inherit;\n\n  img {\n    user-select: none;\n    height: 20px;\n  }\n  &:hover {\n    background: $border-color;\n  }\n\n  &.active {\n    background: $bright-plain-color;\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navButton": `NavButton_navButton__qQmZ-`,
	"active": `NavButton_active__Mz+uS`
};
export default ___CSS_LOADER_EXPORT___;
