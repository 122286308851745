// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChannelsPage_titleDiv__\\+UDiP {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.ChannelsPage_titleDiv__\\+UDiP .ChannelsPage_titleText__6Gxwz {
  font-size: 35px;
  color: #fef9ec;
}

.ChannelsPage_channelCardGrid__g5vz9 {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/channelsPage/ChannelsPage.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;AADF;AAGE;EACE,eAAA;EACA,cCJS;ADGb;;AAKA;EACE,WAAA;AAFF","sourcesContent":["@import \"../../variables\";\n\n.titleDiv {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  margin-bottom: 10px;\n\n  .titleText {\n    font-size: 35px;\n    color: $text-color;\n  }\n}\n\n.channelCardGrid {\n  width: 100%;\n   \n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleDiv": `ChannelsPage_titleDiv__+UDiP`,
	"titleText": `ChannelsPage_titleText__6Gxwz`,
	"channelCardGrid": `ChannelsPage_channelCardGrid__g5vz9`
};
export default ___CSS_LOADER_EXPORT___;
