// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchSuggestions_container__Nb5Zw {
  position: absolute;
  border-radius: 6px;
  overflow: hidden;
  top: 40px;
  display: none;
  width: 100%;
  cursor: auto;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.6);
}
.SearchSuggestions_container__Nb5Zw.SearchSuggestions_opened__oddUr {
  display: flex;
  flex-direction: column;
}

.SearchSuggestions_suggestion__x5ljI {
  display: flex;
  padding-left: 10px;
  height: 50px;
  width: 100%;
  background: #030014;
  font-size: 1.2rem;
  color: white;
  gap: 10px;
  font-weight: bolder;
  align-items: center;
  overflow: hidden;
}
.SearchSuggestions_suggestion__x5ljI:hover {
  background: #101247;
}
.SearchSuggestions_suggestion__x5ljI p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: auto;
  -webkit-user-select: none;
          user-select: none;
}
.SearchSuggestions_suggestion__x5ljI .SearchSuggestions_channelAvatar__f5sIs {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/SearchSuggestions/SearchSuggestions.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,8CAAA;AADF;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,mBCtBiB;EDuBjB,iBAAA;EACA,YAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAGE;EACE,mBC1BgB;ADyBpB;AAGE;EACE,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;UAAA,iBAAA;AADJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;AADJ","sourcesContent":["@import \"../../../variables\";\n\n.container {\n  position: absolute;\n  border-radius: 6px;\n  overflow: hidden;\n  top: 40px;\n  display: none;\n  width: 100%;\n  cursor: auto;\n  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.6);\n  &.opened {\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.suggestion {\n  display: flex;\n  padding-left: 10px;\n  height: 50px;\n  width: 100%;\n  background: $background-color;\n  font-size: 1.2rem;\n  color: white;\n  gap: 10px;\n  font-weight: bolder;\n  align-items: center;\n  overflow: hidden;\n  \n  &:hover {\n    background: $secondary-hovered;\n  }\n  p {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    cursor: auto;\n    user-select: none;\n  }\n  .channelAvatar {\n    width: 35px;\n    height: 35px;\n    object-fit: cover;\n    border-radius: 50%;\n    user-select: none;\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SearchSuggestions_container__Nb5Zw`,
	"opened": `SearchSuggestions_opened__oddUr`,
	"suggestion": `SearchSuggestions_suggestion__x5ljI`,
	"channelAvatar": `SearchSuggestions_channelAvatar__f5sIs`
};
export default ___CSS_LOADER_EXPORT___;
