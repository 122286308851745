// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadPage_content__vCfJo {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.uploadPage_content__vCfJo .uploadPage_head__Ji7Lh {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadPage_content__vCfJo .uploadPage_head__Ji7Lh h1 {
  color: white;
  font-size: 1.2rem;
}
.uploadPage_content__vCfJo .uploadPage_main__U-Tdj {
  height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  scrollbar-width: none;
}
.uploadPage_content__vCfJo .uploadPage_main__U-Tdj h1 {
  color: white;
}
.uploadPage_content__vCfJo .uploadPage_main__U-Tdj::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/uploadPage/uploadPage.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;AAEE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AACI;EACE,YAAA;EACA,iBAAA;AACN;AAEE;EACE,aAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EAKA,qBAAA;AAJJ;AAAI;EACE,YAAA;AAEN;AAEI;EACE,aAAA;AAAN","sourcesContent":["@import \"../../variables\";\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  .head {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    h1 {\n      color: white;\n      font-size: 1.2rem;\n    }\n  }\n  .main{\n    height: 300px;\n    overflow-y: scroll;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    h1{\n      color: white;\n    }\n\n    scrollbar-width: none;\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `uploadPage_content__vCfJo`,
	"head": `uploadPage_head__Ji7Lh`,
	"main": `uploadPage_main__U-Tdj`
};
export default ___CSS_LOADER_EXPORT___;
