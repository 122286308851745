// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoDescripttion_wrapper__-B3Cd {
  width: 100%;
  background: #1d1a2c;
  border-radius: 10px;
  color: white;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.VideoDescripttion_wrapper__-B3Cd:hover {
  background: #3e3a4f;
}
.VideoDescripttion_wrapper__-B3Cd.VideoDescripttion_opened__BgkAs {
  cursor: text;
}
.VideoDescripttion_wrapper__-B3Cd.VideoDescripttion_opened__BgkAs:hover {
  background: #1d1a2c;
}
.VideoDescripttion_wrapper__-B3Cd .VideoDescripttion_topInfo__BoVwp {
  display: flex;
  align-items: center;
  gap: 10px;
}
.VideoDescripttion_wrapper__-B3Cd .VideoDescripttion_topInfo__BoVwp h1 {
  font-size: 1rem;
}
.VideoDescripttion_wrapper__-B3Cd .VideoDescripttion_descBlock__QvDK5 {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.VideoDescripttion_wrapper__-B3Cd .VideoDescripttion_descBlock__QvDK5 p {
  display: block;
}
.VideoDescripttion_wrapper__-B3Cd .VideoDescripttion_descBlock__QvDK5 span {
  margin-top: 10px;
  display: block;
  width: 50px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.VideoDescripttion_wrapper__-B3Cd .VideoDescripttion_descBlock__QvDK5 span:hover {
  color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/VideoDescription/VideoDescripttion.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,mBCGa;EDFb,mBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EAIA,eAAA;AAJF;AACE;EACE,mBCFiB;ADGrB;AAGE;EAIE,YAAA;AAJJ;AACI;EACE,mBCTS;ADUf;AAIE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAFJ;AAGI;EACE,eAAA;AADN;AAKE;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAHJ;AAII;EACE,cAAA;AAFN;AAII;EACE,gBAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;AAFN;AAGM;EACE,gBAAA;AADR","sourcesContent":["@import \"../../../variables\";\n\n.wrapper {\n  width: 100%;\n  background: $border-color;\n  border-radius: 10px;\n  color: white;\n  padding: 10px;\n  margin-top: 10px;\n  &:hover {\n    background: $bright-plain-color;\n  }\n  cursor: pointer;\n\n  &.opened {\n    &:hover {\n      background: $border-color;\n    }\n    cursor: text;\n  }\n\n  .topInfo {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    h1 {\n      font-size: 1rem;\n    }\n  }\n\n  .descBlock {\n    margin-top: 6px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    p {\n      display: block;\n    }\n    span {\n      margin-top: 10px;\n      display: block;\n      width: 50px;\n      cursor: pointer;\n      user-select: none;\n      &:hover {\n        color: lightgray;\n      }\n    }\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `VideoDescripttion_wrapper__-B3Cd`,
	"opened": `VideoDescripttion_opened__BgkAs`,
	"topInfo": `VideoDescripttion_topInfo__BoVwp`,
	"descBlock": `VideoDescripttion_descBlock__QvDK5`
};
export default ___CSS_LOADER_EXPORT___;
