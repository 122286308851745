// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LikeButton_container__h8oRk .LikeButton_dislikeButton__j75Ve, .LikeButton_container__h8oRk .LikeButton_likeButton__ys1nM {
  display: flex;
  border: none;
  outline: none;
  font-family: inherit;
  align-items: center;
  justify-content: center;
  gap: 6px;
  height: 35px;
  padding: 5px 10px 5px 10px;
  background: #1d1a2c;
}
.LikeButton_container__h8oRk .LikeButton_active__JzzFE.LikeButton_dislikeButton__j75Ve, .LikeButton_container__h8oRk .LikeButton_active__JzzFE.LikeButton_likeButton__ys1nM {
  background: #6002ee;
}
.LikeButton_container__h8oRk .LikeButton_active__JzzFE.LikeButton_dislikeButton__j75Ve:hover, .LikeButton_container__h8oRk .LikeButton_active__JzzFE.LikeButton_likeButton__ys1nM:hover {
  background: #4b02b8;
}
.LikeButton_container__h8oRk .LikeButton_dislikeButton__j75Ve:hover, .LikeButton_container__h8oRk .LikeButton_likeButton__ys1nM:hover {
  background: #3e3a4f;
}
.LikeButton_container__h8oRk .LikeButton_dislikeButton__j75Ve img, .LikeButton_container__h8oRk .LikeButton_likeButton__ys1nM img {
  height: 25px;
  width: 25px;
}
.LikeButton_container__h8oRk .LikeButton_dislikeButton__j75Ve p, .LikeButton_container__h8oRk .LikeButton_likeButton__ys1nM p {
  max-width: 100px;
  font-size: 1.1rem;
  color: white;
  font-weight: bolder;
}

.LikeButton_container__h8oRk {
  display: flex;
  align-items: center;
}
.LikeButton_container__h8oRk .LikeButton_likeButton__ys1nM {
  border-radius: 15px 0 0 15px;
}
.LikeButton_container__h8oRk .LikeButton_dislikeButton__j75Ve {
  border-radius: 0 15px 15px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/LikeButton/LikeButton.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,YAAA;EACA,0BAAA;EAUA,mBCda;ADIf;AACE;EACE,mBCZY;ADahB;AAAI;EACE,mBCbY;ADelB;AACE;EACE,mBCXiB;ADYrB;AAEE;EACE,YAAA;EACA,WAAA;AAAJ;AAEE;EACE,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,mBAAA;AADF;AAEE;EAEE,4BAAA;AADJ;AAGE;EAEE,4BAAA;AAFJ","sourcesContent":["@import \"../../../variables\";\n\n%buttonClass {\n  display: flex;\n  border: none;\n  outline: none;\n  font-family: inherit;\n  align-items: center;\n  justify-content: center;\n  gap: 6px;\n  height: 35px;\n  padding: 5px 10px 5px 10px;\n  &.active {\n    background: $primary-color;\n    &:hover {\n      background: $primary-hovered;\n    }\n  } \n  &:hover {\n    background: $bright-plain-color;\n  }\n  background: $border-color;\n  img {\n    height: 25px;\n    width: 25px;\n  }\n  p {\n    max-width: 100px;\n    font-size: 1.1rem;\n    color: white;\n    font-weight: bolder;\n  }\n}\n\n.container {\n  display: flex;\n  align-items: center;\n  .likeButton {\n    @extend %buttonClass;\n    border-radius: 15px 0 0 15px;\n  }\n  .dislikeButton {\n    @extend %buttonClass;\n    border-radius: 0 15px 15px 0;\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LikeButton_container__h8oRk`,
	"dislikeButton": `LikeButton_dislikeButton__j75Ve`,
	"likeButton": `LikeButton_likeButton__ys1nM`,
	"active": `LikeButton_active__JzzFE`
};
export default ___CSS_LOADER_EXPORT___;
