// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageUploader_clickable__uxUhC {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
}
.ImageUploader_clickable__uxUhC img {
  height: 50px;
  width: 50px;
}
.ImageUploader_clickable__uxUhC p {
  font-weight: bolder;
  color: #fef9ec;
  width: 50%;
  text-align: center;
}

.ImageUploader_imageShowFrame__vBPKX {
  border: 2px white solid;
  border-radius: 6px;
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/ImageUploader/ImageUploader.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;AADF;AAEE;EACE,YAAA;EACA,WAAA;AAAJ;AAGE;EACE,mBAAA;EACA,cCVS;EDWT,UAAA;EACA,kBAAA;AADJ;;AAKA;EACE,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAFF","sourcesContent":["@import \"../../../variables\";\n\n.clickable {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  user-select: none;\n  -webkit-user-select: none;\n  img {\n    height: 50px;\n    width: 50px;\n  }\n\n  p {\n    font-weight: bolder;\n    color: $text-color;\n    width: 50%;\n    text-align: center;\n  }\n}\n\n.imageShowFrame {\n  border: 2px white solid;\n  border-radius: 6px;\n  display: flex;\n  width: 100%;\n  height: 200px;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickable": `ImageUploader_clickable__uxUhC`,
	"imageShowFrame": `ImageUploader_imageShowFrame__vBPKX`
};
export default ___CSS_LOADER_EXPORT___;
