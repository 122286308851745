// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChannelCard_card__Pe1\\+k {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  border-radius: 10px;
  padding: 10px;
}
.ChannelCard_card__Pe1\\+k:hover {
  background: #1d1a2c;
}
.ChannelCard_card__Pe1\\+k:active {
  background: #3e3a4f;
}
.ChannelCard_card__Pe1\\+k .ChannelCard_secondLine__Gdzt2 {
  display: flex;
  gap: 8px;
}
.ChannelCard_card__Pe1\\+k .ChannelCard_secondLine__Gdzt2 img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ChannelCard_card__Pe1\\+k .ChannelCard_secondLine__Gdzt2 .ChannelCard_info__uohHf {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  justify-content: center;
}
.ChannelCard_card__Pe1\\+k .ChannelCard_secondLine__Gdzt2 .ChannelCard_info__uohHf h1 {
  color: #fef9ec;
  font-size: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ChannelCard_card__Pe1\\+k .ChannelCard_secondLine__Gdzt2 .ChannelCard_info__uohHf p {
  font-size: 20px;
  color: darkgray;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/ChannelCard/ChannelCard.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,SAAA;EACA,mBAAA;EACA,aAAA;AADF;AAGE;EACE,mBCJW;ADGf;AAIE;EACE,mBCPiB;ADKrB;AAME;EACE,aAAA;EACA,QAAA;AAJJ;AAKI;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;AAHN;AAMI;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,uBAAA;AAJN;AAKM;EACE,cC/BK;EDgCL,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAHR;AAKM;EACE,eAAA;EACA,eAAA;AAHR","sourcesContent":["@import \"../../../variables\";\n\n.card {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 12px;\n  border-radius: 10px;\n  padding: 10px;\n\n  &:hover {\n    background: $border-color;\n  }\n\n  &:active {\n    background: $bright-plain-color;\n  }\n\n\n  .secondLine {\n    display: flex;\n    gap: 8px;\n    img {\n      width: 100px;\n      height: 100px;\n      object-fit: cover;\n      border-radius: 50%;\n      cursor: pointer;\n      user-select: none;\n    }\n\n    .info {\n      display: flex;\n      flex-direction: column;\n      overflow-x: hidden;\n      justify-content: center;\n      h1 {\n        color: $text-color;\n        font-size: 30px;\n        white-space: nowrap;\n        text-overflow: ellipsis;\n        overflow: hidden;\n      }\n      p {\n        font-size: 20px;\n        color: darkgray;\n      }\n    }\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ChannelCard_card__Pe1+k`,
	"secondLine": `ChannelCard_secondLine__Gdzt2`,
	"info": `ChannelCard_info__uohHf`
};
export default ___CSS_LOADER_EXPORT___;
