// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteModal_content__eN-ua .DeleteModal_buttons__t9WfH .DeleteModal_delete__sScfW, .DeleteModal_content__eN-ua .DeleteModal_buttons__t9WfH .DeleteModal_cancel__30PRU {
  border: none;
  outline: none;
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-weight: bolder;
}

.DeleteModal_content__eN-ua {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  width: 100%;
}
.DeleteModal_content__eN-ua .DeleteModal_text__417a0 h2 {
  color: white;
}
.DeleteModal_content__eN-ua .DeleteModal_buttons__t9WfH {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.DeleteModal_content__eN-ua .DeleteModal_buttons__t9WfH .DeleteModal_cancel__30PRU {
  background: #030014;
}
.DeleteModal_content__eN-ua .DeleteModal_buttons__t9WfH .DeleteModal_cancel__30PRU:hover {
  background: #3e3a4f;
}
.DeleteModal_content__eN-ua .DeleteModal_buttons__t9WfH .DeleteModal_cancel__30PRU:active {
  background: #4f4a63;
}
.DeleteModal_content__eN-ua .DeleteModal_buttons__t9WfH .DeleteModal_delete__sScfW {
  background: #8B0000;
}
.DeleteModal_content__eN-ua .DeleteModal_buttons__t9WfH .DeleteModal_delete__sScfW:hover {
  background: #a10303;
}
.DeleteModal_content__eN-ua .DeleteModal_buttons__t9WfH .DeleteModal_delete__sScfW:active {
  background: #dc3545;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Popups/DeleteModal/DeleteModal.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,WAAA;AADF;AAKE;EACE,YAAA;AAHJ;AAOE;EACE,aAAA;EACA,WAAA;EACA,8BAAA;AALJ;AAOI;EAEE,mBChCa;AD0BnB;AAOM;EACE,mBC1Ba;ADqBrB;AAOM;EACE,mBC5Bc;ADuBtB;AASI;EAGE,mBCjCS;ADwBf;AAWM;EACE,mBCnCS;AD0BjB;AAWM;EACE,mBCxCM;AD+Bd","sourcesContent":["@import \"../../../../variables\";\n\n%buttonClass {\n  border: none;\n  outline: none;\n  color: white;\n  padding: 10px;\n  border-radius: 10px;\n  font-weight: bolder;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n  align-items: center;\n  width: 100%;\n\n  .text{\n\n  h2{\n    color: white;\n  }\n}\n\n  .buttons {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n\n    .cancel {\n      @extend %buttonClass;\n      background: $background-color;\n      &:hover {\n        background: $bright-plain-color;\n      }\n      &:active {\n        background: $pressed-plain-color;\n      }\n    }\n\n    .delete {\n\n      @extend %buttonClass;\n      background: $delete-color;\n\n      &:hover {\n        background: $delete-hovered;\n      }\n      &:active {\n        background: $error-color;\n      }\n    }\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `DeleteModal_content__eN-ua`,
	"buttons": `DeleteModal_buttons__t9WfH`,
	"delete": `DeleteModal_delete__sScfW`,
	"cancel": `DeleteModal_cancel__30PRU`,
	"text": `DeleteModal_text__417a0`
};
export default ___CSS_LOADER_EXPORT___;
