// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchPage_content__5YnLt {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.searchPage_content__5YnLt .searchPage_channels__s9W8h {
  border-bottom: 2px solid #1d1a2c;
  padding: 20px;
}
.searchPage_content__5YnLt .searchPage_videos__wiNJs h1 {
  color: white;
  font-size: 35px;
}`, "",{"version":3,"sources":["webpack://./src/pages/searchPage/searchPage.module.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AADF;AAGE;EACE,gCAAA;EACA,aAAA;AADJ;AAII;EACE,YAAA;EACA,eAAA;AAFN","sourcesContent":["@import \"../../variables\";\n\n.content {\n  width: 80%;\n  margin: auto;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n\n  .channels {\n    border-bottom: 2px solid $border-color;\n    padding: 20px;\n  }\n  .videos {\n    h1 {\n      color: white;\n      font-size: 35px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `searchPage_content__5YnLt`,
	"channels": `searchPage_channels__s9W8h`,
	"videos": `searchPage_videos__wiNJs`
};
export default ___CSS_LOADER_EXPORT___;
