// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CButton_cbutton__qXcO9 {
  outline: none;
  border: none;
  min-height: 30px;
  min-width: 70px;
  padding: 10px;
  text-align: center;
  background: #6002ee;
  color: #fef9ec;
  border: none;
  border-radius: 10px;
  font-weight: bolder;
}

.CButton_primary__axMsg {
  background: #6002ee;
}
.CButton_primary__axMsg:hover {
  cursor: pointer;
  background: #4b02b8;
}
.CButton_primary__axMsg:active {
  background: #25286e;
}

.CButton_secondary__vUkSb {
  background: #00022f;
}
.CButton_secondary__vUkSb:hover {
  cursor: pointer;
  background: #101247;
}
.CButton_secondary__vUkSb:active {
  background: #25286e;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CButton/CButton.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;EACA,mBCRc;EDSd,cCJW;EDKX,YAAA;EACA,mBAAA;EACA,mBAAA;AADF;;AAIA;EACE,mBChBc;ADehB;AAEE;EACE,eAAA;EACA,mBClBc;ADkBlB;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,mBCxBgB;ADuBlB;AAGE;EACE,eAAA;EACA,mBC3BgB;AD0BpB;AAIE;EACE,mBAAA;AAFJ","sourcesContent":["@import \"../../../variables\";\n\n.cbutton { \n  outline: none;\n  border: none;\n  min-height: 30px;\n  min-width: 70px;\n  padding: 10px;\n  text-align: center;\n  background: $primary-color;\n  color: $text-color;\n  border: none;\n  border-radius: 10px;\n  font-weight: bolder;\n}\n\n.primary {\n  background: $primary-color;\n  &:hover{\n    cursor: pointer;\n    background: $primary-hovered;\n  }\n  &:active{\n    background: #25286e;\n  }\n}\n\n.secondary {\n  background: $secondary-color; \n\n  &:hover{\n    cursor: pointer;\n    background: $secondary-hovered;\n  }\n\n  &:active{\n    background: #25286e;\n  }\n}\n\n\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cbutton": `CButton_cbutton__qXcO9`,
	"primary": `CButton_primary__axMsg`,
	"secondary": `CButton_secondary__vUkSb`
};
export default ___CSS_LOADER_EXPORT___;
