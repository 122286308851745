// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton_iconButton__MQPvy {
  box-sizing: border-box;
  background-color: transparent;
  min-height: 35px;
  border: none;
  display: flex;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 35px;
}
.IconButton_iconButton__MQPvy img {
  -webkit-user-select: none;
          user-select: none;
  height: 20px;
}
.IconButton_iconButton__MQPvy:hover {
  cursor: pointer;
  background-color: #1d1a2c;
}
.IconButton_iconButton__MQPvy:active {
  background-color: #3e3a4f;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/IconButton/IconButton.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AACA;EACE,sBAAA;EACA,6BAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EAKA,WAAA;AAJF;AAAE;EACE,yBAAA;UAAA,iBAAA;EACA,YAAA;AAEJ;AACE;EACE,eAAA;EACA,yBCXW;ADYf;AACE;EACE,yBCbiB;ADcrB","sourcesContent":["@import \"../../../variables\";\n.iconButton {\n  box-sizing: border-box;\n  background-color: transparent; \n  min-height: 35px;\n  border: none;\n  display: flex;\n  margin-right: 6px;\n  align-items: center;\n  justify-content: center;\n  border-radius: 10px;\n  img {\n    user-select: none;\n    height: 20px;\n  }\n  width: 35px;\n  &:hover{\n    cursor: pointer;\n    background-color: $border-color;\n  }\n  &:active{\n    background-color: $bright-plain-color;\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": `IconButton_iconButton__MQPvy`
};
export default ___CSS_LOADER_EXPORT___;
