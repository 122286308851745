// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorMessage_wrapper__AqeWA {
  width: 100%;
  padding-left: 5px;
}

.ErrorMessage_message__74KEG {
  font-size: 1rem;
  color: #dc3545;
  font-weight: bolder;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/ErrorMessage/ErrorMessage.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,iBAAA;AADF;;AAIA;EACE,eAAA;EACA,cCCY;EDAZ,mBAAA;EACA,uBAAA;AADF","sourcesContent":["@import \"../../../variables\";\n\n.wrapper{\n  width: 100%;\n  padding-left: 5px;\n}\n\n.message{\n  font-size: 1rem;\n  color: $error-color; \n  font-weight: bolder; \n  text-overflow: ellipsis;\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ErrorMessage_wrapper__AqeWA`,
	"message": `ErrorMessage_message__74KEG`
};
export default ___CSS_LOADER_EXPORT___;
