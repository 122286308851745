// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentSection_section__rVIa- {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0;
  align-items: flex-start;
}
.CommentSection_section__rVIa- h1 {
  font-size: 1.1rem;
  color: #fef9ec;
}
.CommentSection_section__rVIa-.CommentSection_Hidden__RbQSm {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/CommentSection/CommentSection.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EAKA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;EACA,uBAAA;AALF;AAHE;EACE,iBAAA;EACA,cAAA;AAKJ;AAEE;EACE,aAAA;AAAJ","sourcesContent":["@import \"../../variables\";\n\n.section {\n  width: 100%;\n  h1 {\n    font-size: 1.1rem;\n    color: $text-color;\n  }\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  padding: 10px 0;\n  align-items: flex-start;\n  &.Hidden{\n    display: none;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `CommentSection_section__rVIa-`,
	"Hidden": `CommentSection_Hidden__RbQSm`
};
export default ___CSS_LOADER_EXPORT___;
