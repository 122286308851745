// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropFileInput_dropField__LhMiu {
  width: 100%;
  height: 300px;
  background: #030014;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DropFileInput_dropField__LhMiu.DropFileInput_draggedOver__AiMRn {
  filter: brightness(50%);
}

.DropFileInput_content__Qe1ge {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  -webkit-user-select: none;
          user-select: none;
  width: 80%;
}
.DropFileInput_contentText__d\\+tqy {
  font-size: 1.2rem;
  text-align: center;
  font-weight: bolder;
  color: white;
}
.DropFileInput_content__Qe1ge .DropFileInput_uploadLink__dtrYJ {
  color: #6002ee;
}
.DropFileInput_content__Qe1ge .DropFileInput_uploadLink__dtrYJ:hover {
  cursor: pointer;
  color: #4b02b8;
}

.DropFileInput_label__ABNTT {
  font-size: 1.1rem;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/DropFileInput/DropFileInput.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,mBCLiB;EDSjB,aAAA;EACA,mBAAA;EACA,uBAAA;AAJF;AADE;EACE,uBAAA;AAGJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;UAAA,iBAAA;EACA,UAAA;AADF;AAGE;EACE,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AADJ;AAIE;EACE,cC7BY;AD2BhB;AAGI;EACE,eAAA;EACA,cC/BY;AD8BlB;;AAOA;EACE,iBAAA;EACA,YAAA;AAJF","sourcesContent":["@import \"../../../variables\";\n\n.dropField {\n  width: 100%;\n  height: 300px;\n  background: $background-color;\n  &.draggedOver {\n    filter: brightness(50%);\n  }\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  user-select: none;\n  width: 80%;\n\n  &Text{\n    font-size: 1.2rem;\n    text-align: center;\n    font-weight: bolder;\n    color: white;\n  }\n\n  .uploadLink {\n    color: $primary-color;\n    &:hover{\n      cursor: pointer;\n      color: $primary-hovered;\n    }\n  } \n  \n}\n\n.label {\n  font-size: 1.1rem;\n  color: white; \n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropField": `DropFileInput_dropField__LhMiu`,
	"draggedOver": `DropFileInput_draggedOver__AiMRn`,
	"content": `DropFileInput_content__Qe1ge`,
	"contentText": `DropFileInput_contentText__d+tqy`,
	"uploadLink": `DropFileInput_uploadLink__dtrYJ`,
	"label": `DropFileInput_label__ABNTT`
};
export default ___CSS_LOADER_EXPORT___;
