// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextArea_container__4BmqI {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid white;
  border-radius: 6px;
  padding: 5px;
}
.TextArea_container__4BmqI.TextArea_tooLong__gDK9G {
  border: 2px solid #dc3545;
}
.TextArea_container__4BmqI.TextArea_inputType__vDEJj {
  flex-direction: row;
  gap: 6px;
}

.TextArea_area__53SVo {
  overflow: hidden;
  padding-top: 4px;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  height: 24px;
  font-family: inherit;
  resize: none;
  outline: none;
  border: none;
  background: #030014;
  padding: 5px;
  color: #fef9ec;
  font-size: 1.2rem;
  width: 100%;
}
.TextArea_area__53SVo.TextArea_tooLong__gDK9G {
  color: #dc3545;
}

.TextArea_letterCount__-Wzvk {
  font-weight: bolder;
  color: gray;
  align-self: self-end;
}
.TextArea_letterCount__-Wzvk.TextArea_tooLong__gDK9G {
  color: #dc3545;
}

.TextArea_label__tTyWQ {
  font-size: 1.1rem;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/TextArea/TextArea.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EAQE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;AARF;AAJE;EACE,yBAAA;AAMJ;AAJE;EACE,mBAAA;EACA,QAAA;AAMJ;;AAIA;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,mBC7BiB;ED8BjB,YAAA;EACA,cCzBW;ED6BX,iBAAA;EACA,WAAA;AAJF;AAAE;EACE,cCvBU;ADyBd;;AAIA;EAIE,mBAAA;EACA,WAAA;EACA,oBAAA;AAJF;AADE;EACE,cC/BU;ADkCd;;AAIA;EACE,iBAAA;EACA,YAAA;AADF","sourcesContent":["@import \"../../../variables\";\n\n.container {\n  &.tooLong {\n    border: 2px solid $error-color;\n  }\n  &.inputType {\n    flex-direction: row;\n    gap: 6px;\n  }\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  border: 2px solid white;\n  border-radius: 6px;\n  padding: 5px;\n}\n\n.area {\n  overflow: hidden;\n  padding-top: 4px;\n  width: 100%;\n  font-size: 16px;\n  line-height: 18px;\n  height: 24px;\n  font-family: inherit;\n  resize: none;\n  outline: none;\n  border: none;\n  background: $background-color;\n  padding: 5px;\n  color: $text-color;\n  &.tooLong {\n    color: $error-color;\n  }\n  font-size: 1.2rem;\n  width: 100%;\n}\n\n.letterCount {\n  &.tooLong {\n    color: $error-color;\n  }\n  font-weight: bolder;\n  color: gray;\n  align-self: self-end;\n}\n\n.label {\n  font-size: 1.1rem;\n  color: white;\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TextArea_container__4BmqI`,
	"tooLong": `TextArea_tooLong__gDK9G`,
	"inputType": `TextArea_inputType__vDEJj`,
	"area": `TextArea_area__53SVo`,
	"letterCount": `TextArea_letterCount__-Wzvk`,
	"label": `TextArea_label__tTyWQ`
};
export default ___CSS_LOADER_EXPORT___;
