// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserPopup_content__UNMvX {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.UserPopup_content__UNMvX .UserPopup_error__YowNO {
  color: #dc3545;
}

.UserPopup_avatarChange__oYG9A {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.UserPopup_avatarChange__oYG9A img {
  cursor: pointer;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}
.UserPopup_avatarChange__oYG9A h1 {
  color: white;
  font-size: 1.3rem;
  cursor: pointer;
}

.UserPopup_buttons__asM2J {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Popups/UserPopup/UserPopup.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;AAEE;EACE,cCGU;ADHd;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AADF;AAEE;EACE,eAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AAAJ;AAEE;EACE,YAAA;EACA,iBAAA;EACA,eAAA;AAAJ;;AAIA;EACE,aAAA;EACA,8BAAA;AADF","sourcesContent":["@import \"../../../../variables\";\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  .error{\n    color: $error-color;\n  }\n}\n\n.avatarChange{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  img{\n    cursor: pointer;\n    border-radius: 50%;\n    height: 150px;\n    width: 150px;\n  }\n  h1{\n    color: white;\n    font-size: 1.3rem;\n    cursor: pointer;\n  }\n}\n\n.buttons{\n  display: flex;\n  justify-content: space-between;\n}\n\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `UserPopup_content__UNMvX`,
	"error": `UserPopup_error__YowNO`,
	"avatarChange": `UserPopup_avatarChange__oYG9A`,
	"buttons": `UserPopup_buttons__asM2J`
};
export default ___CSS_LOADER_EXPORT___;
