// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChannelPopup_content__7jkPH {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ChannelPopup_content__7jkPH .ChannelPopup_error__F7Sxa {
  color: #dc3545;
}

.ChannelPopup_avatarChange__Cte9o {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.ChannelPopup_avatarChange__Cte9o .ChannelPopup_bbc__TttvK {
  width: 100%;
  height: 80px;
  border-radius: 20px;
  cursor: pointer;
}
.ChannelPopup_avatarChange__Cte9o img {
  cursor: pointer;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}
.ChannelPopup_avatarChange__Cte9o h1 {
  color: white;
  font-size: 1.3rem;
  cursor: pointer;
}

.ChannelPopup_buttons__ABcR7 {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Popups/ChannelPopup/ChannelPopup.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;AAEE;EACE,cCGU;ADHd;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AADF;AAEE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEE;EACE,eAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AAAJ;AAEE;EACE,YAAA;EACA,iBAAA;EACA,eAAA;AAAJ;;AAIA;EACE,aAAA;EACA,8BAAA;AADF","sourcesContent":["@import \"../../../../variables\";\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  .error {\n    color: $error-color;\n  }\n}\n\n.avatarChange {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  .bbc{\n    width: 100%;\n    height: 80px;\n    border-radius: 20px; \n    cursor: pointer;\n  }\n  img {\n    cursor: pointer;\n    border-radius: 50%;\n    height: 150px;\n    width: 150px;\n  }\n  h1 {\n    color: white;\n    font-size: 1.3rem;\n    cursor: pointer;\n  }\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `ChannelPopup_content__7jkPH`,
	"error": `ChannelPopup_error__F7Sxa`,
	"avatarChange": `ChannelPopup_avatarChange__Cte9o`,
	"bbc": `ChannelPopup_bbc__TttvK`,
	"buttons": `ChannelPopup_buttons__ABcR7`
};
export default ___CSS_LOADER_EXPORT___;
