// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowButton_showButton__2lgoP {
  padding: 8px;
  border: 2px solid #24a0ed;
  border-radius: 20px;
  cursor: pointer;
  background: #030014;
  color: #24a0ed;
  font-family: inherit;
  font-weight: bolder;
  font-size: 13px;
}
.ShowButton_showButton__2lgoP:hover {
  background: #0d5380;
}
.ShowButton_showButton__2lgoP:active {
  background: #3e3a4f;
}
.ShowButton_showButton__2lgoP.ShowButton_wide__7mqWp {
  width: 100%;
  font-size: 17px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/ShowButton/ShowButton.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,mBCPiB;EDQjB,cCKW;EDJX,oBAAA;EACA,mBAAA;EACA,eAAA;AADF;AAEE;EACE,mBCEW;ADFf;AAEE;EACE,mBCRiB;ADQrB;AAEE;EACE,WAAA;EACA,eAAA;AAAJ","sourcesContent":["@import \"../../../variables\";\n\n.showButton {\n  padding: 8px;\n  border: 2px solid $blue-color;\n  border-radius: 20px;\n  cursor: pointer;\n  background: $background-color;\n  color: $blue-color;\n  font-family: inherit;\n  font-weight: bolder;\n  font-size: 13px;\n  &:hover {\n    background: $blue-clicked;\n  }\n  &:active {\n    background: $bright-plain-color;\n  }\n  &.wide{\n    width: 100%;\n    font-size: 17px;\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showButton": `ShowButton_showButton__2lgoP`,
	"wide": `ShowButton_wide__7mqWp`
};
export default ___CSS_LOADER_EXPORT___;
