// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__lqUPL {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 6px solid #3e3a4f;
  border-right-color: #6002ee;
  animation: Loader_spin__RjWvZ 1s ease infinite;
}

@keyframes Loader_spin__RjWvZ {
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Loader/Loader.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,2BCNc;EDOd,8CAAA;AADF;;AAIA;EACE;IACE,yBAAA;EADF;AACF","sourcesContent":["@import \"../../../variables\";\n\n.loader {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  border: 6px solid $bright-plain-color; \n  border-right-color: $primary-color;\n  animation: spin 1s ease infinite;\n}\n\n@keyframes spin{\n  100%{\n    transform: rotate(360deg);\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__lqUPL`,
	"spin": `Loader_spin__RjWvZ`
};
export default ___CSS_LOADER_EXPORT___;
