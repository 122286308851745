// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuWindow_overlay__uV7o2 {
  display: none;
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.MenuWindow_overlay__uV7o2.MenuWindow_active__MtbF5 {
  display: flex;
}

.MenuWindow_menuWindow__L7z00 {
  position: fixed;
  height: calc(100dvh - 60px);
  top: 60px;
  width: 220px;
  z-index: 2;
  background: #030014;
  left: -220px;
  transition: left 0.3s ease;
}
.MenuWindow_menuWindow__L7z00.MenuWindow_open__YXIhl {
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/MenuWindow/MenuWindow.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;EACA,8BAAA;EACA,UAAA;AADF;AAGE;EACE,aAAA;AADJ;;AAKA;EACE,eAAA;EACA,2BAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;EACA,mBCvBiB;EDwBjB,YAAA;EACA,0BAAA;AAFF;AAGE;EACE,OAAA;AADJ","sourcesContent":["@import \"../../variables\";\n\n.overlay {\n  display: none;\n  position: fixed;\n  top: 60px;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.4);\n  z-index: 1;\n\n  &.active {\n    display: flex;\n  } \n}\n\n.menuWindow {\n  position: fixed;\n  height: calc(100dvh - 60px);\n  top: 60px;\n  width: 220px;\n  z-index: 2;\n  background: $background-color;\n  left: -220px;\n  transition: left 0.3s ease;\n  &.open{\n    left: 0;\n  }\n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `MenuWindow_overlay__uV7o2`,
	"active": `MenuWindow_active__MtbF5`,
	"menuWindow": `MenuWindow_menuWindow__L7z00`,
	"open": `MenuWindow_open__YXIhl`
};
export default ___CSS_LOADER_EXPORT___;
