// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popup_popup__SKA6\\+ {
  position: fixed;
  top: -100vh;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Popup_popup__SKA6\\+.Popup_active__EOTcm {
  top: 0;
  transition: top 0ms ease-in-out 0ms;
}
.Popup_popup__SKA6\\+.Popup_active__EOTcm .Popup_overlay__uad8W {
  opacity: 1;
  transition: all 300ms ease-in-out;
}
.Popup_popup__SKA6\\+.Popup_active__EOTcm .Popup_popupContent__lxcSg {
  transform: scale(1);
  opacity: 1;
}

.Popup_overlay__uad8W {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: opacity 100ms ease-in-out 200ms;
}

.Popup_popupContent__lxcSg {
  transform: scale(1.05);
  opacity: 0;
  background: #030014;
  max-width: 400px;
  width: 95%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.6);
  transition: transform 150ms ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Popups/Popup.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,WAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFF;AAKE;EACE,MAAA;EACA,mCAAA;AAHJ;AAKI;EACE,UAAA;EACA,iCAAA;AAHN;AAMI;EACE,mBAAA;EACA,UAAA;AAJN;;AAUA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,UAAA;EACA,8BAAA;EACA,2CAAA;AAPF;;AAUA;EACE,sBAAA;EACA,UAAA;EACA,mBC9CiB;ED+CjB,gBAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,8CAAA;EACA,uCAAA;AAPF","sourcesContent":["@import \"../../../variables\";\n\n.popup{\n  position: fixed;\n  top: -100vh;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 100;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n\n  &.active{\n    top: 0;\n    transition: top 0ms ease-in-out 0ms;\n\n    .overlay{\n      opacity: 1;\n      transition: all 300ms ease-in-out;\n    }\n\n    .popupContent{\n      transform: scale(1);\n      opacity: 1;\n    }\n  }\n}\n\n\n.overlay {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  opacity: 0;\n  background: rgba(0, 0, 0, 0.6);\n  transition: opacity 100ms ease-in-out 200ms;\n}\n\n.popupContent {\n  transform: scale(1.05);\n  opacity: 0;\n  background: $background-color;\n  max-width: 400px;\n  width: 95%;\n  padding: 20px;\n  border-radius: 20px;\n  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.6);\n  transition: transform 150ms ease-in-out;  \n}\n","$background-color: #030014;\n$primary-color: #6002ee;\n$primary-hovered: #4b02b8;\n$primary-pressed: #3c0391;\n$secondary-color: #00022f;\n$secondary-hovered: #101247;\n$text-color: #fef9ec;\n$border-color: #1d1a2c;\n$bright-plain-color: #3e3a4f;\n$pressed-plain-color: #4f4a63;\n$error-color: #dc3545;\n$delete-color: #8B0000;\n$delete-hovered: #a10303;\n$blue-color: #24a0ed;\n$blue-hovered: #217cb5;\n$blue-clicked: #0d5380;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `Popup_popup__SKA6+`,
	"active": `Popup_active__EOTcm`,
	"overlay": `Popup_overlay__uad8W`,
	"popupContent": `Popup_popupContent__lxcSg`
};
export default ___CSS_LOADER_EXPORT___;
